import React from "react";

import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";

const CTA = ({ heading, headingLevel, subtext, className }) => {
  const HeadingTag = headingLevel || "h2";

  return (
    <section
      className={`bg-primary-50 pt-14 md:pt-20 pb-18 md:pb-10 ${
        className || ""
      }`}
    >
      <div className="container">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-end md:mb-8">
          <header className="max-w-3xl text-center md:text-left">
            <HeadingTag>
              {heading || ["Have Questions? Want To Check Rates?"]}
            </HeadingTag>

            <svg
              width="100%"
              height="10"
              viewBox="0 0 318 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="md:hidden mb-6"
            >
              <path
                d="M0 0.978516H318L315.381 9.97852H0V0.978516Z"
                fill="#1E291D"
              />
            </svg>

            <p className="font-display italic text-xl md:text-2xl text-gray-800 md:mb-0">
              {subtext ||
                "The MacAsh mortgage lender team would love to help. Let’s connect!"}
            </p>
          </header>

          <div className="flex items-center space-x-4">
            <ButtonGhost
              modal="modal-contact"
              text="Contact Us"
              className="w-full md:w-auto"
            />
            <ButtonSolid
              href="/request-rates/"
              text="Request Rates"
              className="w-full md:w-auto"
            />
          </div>
        </div>
        <svg
          width="100%"
          height="9"
          viewBox="0 0 1214 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="hidden md:block"
        >
          <path d="M0 0H1214L1204 9H0V0Z" fill="#1E291D" />
        </svg>
      </div>
    </section>
  );
};

export default CTA;
